import { Component, OnInit, ViewEncapsulation, inject } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { LoadingSpinnerComponent } from './shared/components/loading-spinner/loading-spinner.component';
import { AuthService } from './shared/services/auth/auth.service';

@Component({
  standalone: true,
  imports: [RouterOutlet, LoadingSpinnerComponent],
  selector: 'dpqa-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.less',
  encapsulation: ViewEncapsulation.Emulated,
})
export class AppComponent implements OnInit {
  private translateService = inject(TranslateService);
  private authService = inject(AuthService);

  public isAuthenticated = this.authService.isAuthenticated;

  ngOnInit(): void {
    this.translateService.setDefaultLang('en');
    this.translateService.use('en');
  }
}
