import { type Environment } from './environment.interface';

export const environment: Environment = {
  production: true,
  oauth_discovery_uri:
    'https://barcociamdevstable.b2clogin.com/barcociamdevstable.onmicrosoft.com/v2.0/.well-known/openid-configuration?p=B2C_1A_Signin',
  oauth_scope: 'openid offline_access https://barcociamdevstable.onmicrosoft.com/digitalpath/api',
  oauth_client_id: 'c3003dcb-e6e1-41bb-87db-b4aaae291b7b',
  version: '__GIT_COMMIT_ID__', // Change the value to __GIT_COMMIT_ID__ in case of any other value. It is being used by build script to change to git commit id.
};
