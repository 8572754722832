import { HttpErrorResponse, type HttpInterceptorFn, type HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';
import { catchError, of, switchMap, throwError } from 'rxjs';
import type { AbstractAuthService } from '../../shared/services/auth/abstract-auth.service';
import { AuthService } from '../../shared/services/auth/auth.service';

const addAuthHeaders = (authService: AbstractAuthService, req: HttpRequest<unknown>) =>
  req.clone({
    ...(req.url.includes('result.json') // Not setting auth headers when we request the ai results json
      ? {}
      : {
          setHeaders: {
            Authorization: `Bearer ${authService.getAccessToken()}`,
          },
        }),
  });

export const authorizationInterceptor: HttpInterceptorFn = (req, next) => {
  const authService = inject(AuthService);

  return next(addAuthHeaders(authService, req)).pipe(
    catchError((err) => {
      if (err instanceof HttpErrorResponse && err.status === 401) {
        // asserting its not undefined because page will be exited
        return authService.refreshToken().pipe(
          switchMap(() => next(addAuthHeaders(authService, req))),
          catchError((errAfterRefresh) => {
            if (errAfterRefresh instanceof HttpErrorResponse && [401].includes(errAfterRefresh.status)) {
              authService.logout();
            }

            return of(errAfterRefresh);
          }),
        );
      }
      return throwError(() => err);
    }),
  );
};
